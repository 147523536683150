var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',_vm._l((_vm.list),function(item,index){return _c('b-row',{key:index,staticClass:"mb-3"},[_c('b-col',{attrs:{"sm":"3"}},[_c('label',{staticClass:"font-weight-bold main-label"},[_vm._v("ตัวเลือก #"+_vm._s(index + 1))]),(
          item.option[0].label.length == 0 ||
            (!_vm.v.$each.$iter[index].label && _vm.v.$each.$iter[index].label.$error)
        )?_c('div',[_c('span',{staticClass:"text-error"},[_vm._v("กรุณากรอกข้อมูลอย่างน้อย 1 ตัวเลือก")])]):_vm._e()]),_c('b-col',{attrs:{"sm":"9"}},[_c('div',{staticClass:"ops-box py-3 px-4 position-relative"},[_c('b-form-group',{attrs:{"id":"fieldset-horizontal","label-cols-sm":"3","label":'ชื่อตัวเลือก',"label-for":"ops-name","label-align-sm":"right"}},[_c('b-form-input',{staticClass:"input-ops",attrs:{"id":"ops-name","disabled":true},on:{"keyup":function($event){return _vm.onChangeOptionsName(index)}},model:{value:(item.label),callback:function ($$v) {_vm.$set(item, "label", $$v)},expression:"item.label"}}),(
              _vm.v.$each.$iter[index].label && _vm.v.$each.$iter[index].label.$error
            )?_c('div',[(_vm.v.$each.$iter[index].label.required == false)?_c('span',{staticClass:"text-error"},[_vm._v("กรุณากรอกข้อมูล ")]):_vm._e()]):_vm._e()],1),_vm._l((item.option),function(item2,index2){return _c('div',{key:index2},[_c('b-form-group',{attrs:{"id":"fieldset-horizontal","label-cols-sm":"3","label":'ตัวเลือก' + ' ' + (index2 + 1),"label-for":"ops-1","label-align-sm":"right"}},[_c('div',{staticClass:"d-flex"},[_c('b-form-input',{staticClass:"input-ops",attrs:{"id":"ops-1","disabled":true},on:{"keyup":function($event){return _vm.onChangeOptionsChoiceName(index, index2)}},model:{value:(item2.label),callback:function ($$v) {_vm.$set(item2, "label", $$v)},expression:"item2.label"}})],1),(
                _vm.v.$each.$iter[index].option.$each.$iter[index2].label &&
                  _vm.v.$each.$iter[index].option.$each.$iter[index2].label.$error
              )?_c('div',[(
                  _vm.v.$each.$iter[index].option.$each.$iter[index2].label
                    .required == false
                )?_c('span',{staticClass:"text-error"},[_vm._v("กรุณากรอกข้อมูล ")]):_vm._e()]):_vm._e()])],1)})],2)])],1)}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }